<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <div class="tabs">
                        <router-link to="/set-jiliangbiao" class="lk">计量表</router-link>
                        <router-link to="" class="lk cur">传感器</router-link>
                        <router-link to="/set-virtual" class="lk">虚拟表</router-link>
                        <router-link to="/set-outline" class="lk">离线表</router-link>
                        <router-link to="/set-outlineFile" class="lk">离线表文件</router-link>
                        <router-link to="/set-gaugesManage" class="lk">绑定数据点</router-link>
                    </div>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <el-form ref="form" class="top-form" :model="form">
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <el-form-item>
                                    <el-select v-model="equimentId" placeholder="选择设备">
                                        <el-option v-for="item in equiments" :key="item.value" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="未选择" value="-1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="20">
                                <div class="flex flex-pack-justify">
                                    <button class="ann annbg1" @click="getSensorList">
                                        查询
                                    </button>
                                    <button class="ann annbg1 addbtn" @click="onAdd()">
                                        添加传感器
                                    </button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" width="60" label="ID">
                            </el-table-column>
                            <el-table-column prop="name" label="名称"> </el-table-column>
                            <el-table-column prop="des" label="描述"> </el-table-column>
                            <el-table-column label="操作" width="150">
                                <template slot-scope="scope">
                                    <span class="lk c-main" @click="handleEdit(scope.$index, scope.row)">修改</span>
                                    <span class="lk c-red" @click="handleDelete(scope.$index, scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                    
                </div>
            </div>
        </div>
        <!-- 添加传感器-->
        <el-dialog :visible.sync="addPop" width="40%">
            <div class="pop">
                <div class="toptt">
                    <span class="backbtn">添加传感器</span>
                    <i class="el-icon-close close" @click="addPop = false"></i>
                    <div class="dix"></div>
                </div>
                <div class="detail">
                    <el-form ref="form" :model="addForm" label-width="120px">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="名称:">
                                    <el-input v-model="addForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="描述:">
                                    <el-input v-model="addForm.miaoshu"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <el-form-item label="所属设备:">
                                    <el-select v-model="addForm.shebei" multiple placeholder="选择所属设备"
                                        @change="$forceUpdate()">
                                        <el-option v-for="item in equiments" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <div class="btngroup">
                            <button class="ann confirm" @click="onSubmit">确定</button>
                            <button class="ann back" @click="addPop = false">返回</button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { getEquipList } from "@/utils/common";
import { sensorList, delSensor, addSensor, editSensor } from "@/api/meter";
export default {
    data() {
        return {
            equimentId: "",
            equimentName: "未选择",
            equiments: [],
            tableData: [],
            pageInfo: {
                total: 0,
                pageSize: 10,
                pageNum: 1,
            },
            loading: false,
            addPop: false,
            addForm: {
                name: "",
                miaoshu: "",
                shebei: [],
            },
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.equiments = getEquipList();
    },
    mounted() { },
    watch: {},
    methods: {
        handleEdit(index, row) {
            console.log(row);
            this.addForm.name = row.name;
            this.addForm.miaoshu = row.des;
            this.editId = row.id;
            if (row.deviceIds != "") {
                this.addForm.shebei = row.deviceIds.split(",").map(Number);
            }
            this.addPop = true;
        },
        handleDelete(index, row) {
            this.$confirm("此操作将永久删除该传感器, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.deleteSensor(row.id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getSensorList();
        },
        //获取传感器列表
        getSensorList() {
            this.loading = true;
            let param = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
                deviceId: this.equimentId,
            };
            if (this.equimentId == "-1") {
                delete param.deviceId;
            }
            sensorList(param).then((res) => {
                if (res.data.code == 0) {
                    this.pageInfo.total = res.data.total;
                    this.tableData = res.data.rows;
                } else {
                    this.$message.warning(res.data.msg);
                }
                this.loading = false;
            });
        },
        //删除具体传感器
        deleteSensor(id) {
            let param = {
                ids: String(id),
            };
            delSensor(param).then((res) => {
                if (res.data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                } else {
                    this.$message.warning(res.data.msg);
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                }
            });
        },
        //添加弹窗
        onAdd() {
            this.editId = -1;
            (this.addForm = {
                name: "",
                miaoshu: "",
                shebei: [],
            }),
                (this.addPop = true);
        },
        //添加/修改传感器
        onSubmit() {
            if (this.addForm.name == "") {
                this.$message.warning("请填写传感器名");
            } else {
                let param = {
                    name: this.addForm.name,
                    des: this.addForm.miaoshu,
                    deviceIds: this.addForm.shebei.join(","),
                };
                if (this.editId == -1) {
                    addSensor(param).then((res) => {
                        if (res.data.code != 0) {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.addPop = false;
                            this.getSensorList();
                        }
                    });
                } else {
                    param.id = this.editId;
                    editSensor(param).then((res) => {
                        if (res.data.code != 0) {
                            this.$message.warning(res.data.msg);
                        } else {
                            this.addPop = false;
                            this.getSensorList();
                        }
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.pop .detail {
    padding-right: 20px;
}
</style>
